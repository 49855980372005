<template>
  <div class="favorite"
       v-on:click="addToFavorites">
    <div class="favorite__wrapper">
      <Row v-if="ready">
        <Icon class="favorite__icon"
              :xlink="params.favorited ? 'bookmark-filled' : 'bookmark'"
              viewport="0 0 30 30"/>
        <slot/>
      </Row>
      <Loader v-else :params="{style}"/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Favorite',
  props: {
    params: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    ready: true,
    style: {
      width: '30px',
      height: '30px',
    },
  }),
  methods: {
    addToFavorites() {
      this.ready = false;
      this.$http.post(`/api${this.params.link.replace('/content', '')}/favorites`)
        .then(() => {
          this.$http.get(`/api${this.params.link}`)
            .then((resolve) => {
              this.params.favorited = Boolean(resolve.data.data.favorited);

              if (this.params.link.split('/')
                .some(item => item === 'event')) {
                this.$store.commit('UPDATE_EVENTS', resolve.data.data);
                this.$store.dispatch('GET_FAVORITES_EVENTS_FROM_SERVER');
              }
              if (this.params.link.split('/')
                .some(item => item === 'places')) {
                this.$store.commit('UPDATE_PLACES', resolve.data.data);
                this.$store.dispatch('GET_FAVORITES_PLACES_FROM_SERVER');
              }
              if (resolve.data.data.type === 'route' || resolve.data.data.type === 'excursion') {
                if (resolve.data.data.type === 'route') {
                  this.$store.commit('UPDATE_TRIPS', resolve.data.data);
                }
                if (resolve.data.data.type === 'excursion') {
                  this.$store.commit('UPDATE_EXCURSIONS', resolve.data.data);
                }
                this.$store.dispatch('GET_FAVORITES_EXCURSIONS_FROM_SERVER');
              }
            })
            .then(() => {
              this.ready = true;
            });
        });
    },
  },
};
</script>
